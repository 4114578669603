navMenu();
function navMenu() {
  $('.c-btn-menu').on('click', function () {
    if ( $(this).hasClass('-active') ) {
      // close
      $(this).removeClass('-active');
      $('.o-nav-menu').addClass('-close').removeClass('-open');
      $('body').addClass('-scroll').removeClass('-fixed');
      $(window).scrollTop(scroll);
    } else {
      // open
      scroll = $(window).scrollTop();
      $(this).addClass('-active');
      $('.o-nav-menu').addClass('-open').removeClass('-close');
      $('body').addClass('-fixed').removeClass('-scroll');
      $('body').css({
        'top': -1*scroll,
      });
    }
    $('.o-nav-menu a').on('click',function() {
      $('.c-btn-menu').removeClass('-active');
      $('.o-nav-menu').removeClass('-open');
      $('body').css('position','static');
    });

    $('.o-nav-menu__link .-parent p').on('click',function() {
      $(this).stop().toggleClass('-active');
      $(this).siblings('ul').stop().slideToggle();
    });
  });
}