$(function() {
  const slider = $('.o-mainvisual__slick');
  slider.slick({
    autoplay: true,
    autoplaySpeed: 5500,
    speed: 3000,
    fade: true,
    arrows: false,
    dots: true,
    dotsClass: 'o-mainvisual__dots',
    pauseOnFocus: false,
    pauseOnHover: false,
  });

  if (windowWidth > windowSm) {
    $('.o-mainvisual__dots li').append('<svg><circle cx="25" cy="25" r="24"></circle></svg>');
  } else {
    $('.o-mainvisual__dots li').append('<svg><circle cx="19" cy="19" r="18"></circle></svg>');
  }

  slider.on('beforeChange',function(event, slick, currentSlide, nextSlide){
    if(nextSlide > 0 ) {
      slider.slick('slickSetOption', 'autoplaySpeed', 2500, true);
      if (windowWidth > windowSm) {
        $('.o-mainvisual__dots li').append('<svg><circle cx="25" cy="25" r="24"></circle></svg>');
      } else {
        $('.o-mainvisual__dots li').append('<svg><circle cx="19" cy="19" r="18"></circle></svg>');
      }
    }
  })


  if (windowWidth > windowSm) {
    $('.o-index-slider__list').slick({
      speed: 700,
      arrows: true,
      prevArrow: '<div class="o-index-slider__list__prev"></div>',
      nextArrow: '<div class="o-index-slider__list__next"></div>',
      slidesToShow: 4,
      pauseOnFocus: false,
      pauseOnHover: false,
      infinite: false,
    });
  } else {
    $('.o-index-slider__list').slick({
      speed: 700,
      prevArrow: '<div class="o-index-slider__list__prev"></div>',
      nextArrow: '<div class="o-index-slider__list__next"></div>',
      slidesToShow: 2,
      pauseOnFocus: false,
      pauseOnHover: false,
      infinite: false,
    });
  }
});