// top page js

topFunction();

function topFunction(){
  if (!document.body.classList.contains('home')) return;
  loading();
}

function loading() {
  const images = document.querySelectorAll('img')
  const loadingCount = document.querySelector('.c-loading__count')
  const loadingProgress = document.querySelector(
    '.c-loading__indicator__progress'
  )
  let counter
  let cnt = 0

  for (let i = 0; i < images.length; i++) {
    let img = new Image()
    img.onload = () => {
      cnt += 1
    }
    img.onerror = () => {
      cnt += 1
    }
    img.src = images[i].src
  }

  _animation()
  function _animation() {
    counter = images.length ? Math.floor((cnt / images.length) * 100) : 100
    loadingCount.innerHTML = counter
    loadingProgress.style.width = `${counter}%`
    if (cnt == images.length) {
      cancelAnimationFrame(_animation)
      document.body.classList.add('-loaded')
    } else {
      requestAnimationFrame(_animation)
    }
  }
}

if (windowWidth > windowSm) {
  ;
} else {
  $(function() {
    var linkBtn = $('.o-gnav-sp');    
    linkBtn.hide();
    $(window).scroll(function () {
      var scrollHeight = $(document).height();
      var scrollPosition = $(window).height() + $(window).scrollTop();
      if ($(this).scrollTop() < 100) {
        linkBtn.fadeOut();
      } else if ((scrollHeight - scrollPosition) / scrollHeight <= 0.05) { // スクロールの位置が下部5%の範囲に来た場合
        linkBtn.fadeOut();
      } else {
        linkBtn.fadeIn();
      }
    });
  });
}
